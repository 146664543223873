/*
 * @Auth: linjituan
 * @Date: 2022-03-14 10:21:14
 * @LastEditors: linjituan
 * @LastEditTime: 2022-03-17 16:47:44
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询回收物种类列表
export function listRecyclingKind(query) {
  return request({
    url: '/internal/recyclable/recyclingKind/list',
    baseURL,
    method: 'get',
    params: query
  })
}

// 查询回收物种类详细
export function getRecyclingKind(id) {
  return request({
    url: '/internal/recyclable/recyclingKind/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增回收物种类
export function addRecyclingKind(data) {
  return request({
    url: '/internal/recyclable/recyclingKind',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改回收物种类
export function updateRecyclingKind(data) {
  return request({
    url: '/internal/recyclable/recyclingKind',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除回收物种类
export function delRecyclingKind(id) {
  return request({
    url: '/internal/recyclable/recyclingKind/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出回收物种类
export function exportRecyclingKind(query) {
  return request({
    url: '/internal/recyclable/recyclingKind/export',
    baseURL,
    method: 'get',
    params: query
  })
}

// 导出回收物种类
export function adjustorder(data) {
  return request({
    url: '/internal/recyclable/recyclingKind/adjustorder',
    baseURL,
    method: 'post',
    data: data
  })
}

// 根据parentId 查询列表
export function childrenList(parentId) {
  return request({
    url: '/internal/recyclable/recyclingKind/list/' + parentId,
    baseURL,
    method: 'get'
  })
}
