/*
 * @Auth: linjituan
 * @Date: 2022-04-07 18:16:21
 * @LastEditors: linjituan
 * @LastEditTime: 2022-05-30 18:12:24
 */
import request from '@/utils/request'
const baseURL = '/rc-api'
// 查询回收物种类列表
export function statistics(data) {
  return request({
    url: '/internal/recyclable/resourceSum/statistics',
    baseURL,
    method: 'post',
    data: data
  })
}

// 回收物种类导出
export function resourceSumExport(data) {
  return request({
    url: '/internal/recyclable/resourceSum/export',
    baseURL,
    method: 'post',
    responseType: 'blob',
    data: data
  })
}
