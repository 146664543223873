<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="驿站名称" prop="stationIds">
                <a-select
                  show-search
                  allowClear
                  style="width: 100%"
                  placeholder="请输入驿站名称模糊查找"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleQuerySearch"
                  @change="handleQueryChange"
                  :loading="hsloading"
                  v-model="queryParam.stationIds"
                >
                  <a-select-option v-for="(item, index) in stationListQuery" :key="index" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="垃圾种类" prop="scaleIds">
                <a-tree-select
                  v-model="queryParam.scaleIds"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="kindList"
                  allow-clear
                  multiple
                  placeholder="请选择"
                  :replaceFields="replaceFields"
                  tree-default-expand-all
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="日期时间" prop="daterangeCreateTime">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeCreateTime"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:recyclable:bankInfo:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:recyclable:bankInfo:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:recyclable:bankInfo:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport"> <a-icon type="download" />导出 </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <div class="sum-wrap">
        <div class="sum-item">驿站数量总计：{{ statisticsInfo.countStation || 0 }}</div>
        <div class="sum-item">智能秤总计：{{ statisticsInfo.countScale || 0 }}</div>
        <div class="sum-item">总价格1（已去杂）：{{ statisticsInfo.totalAmount || 0 }}</div>
        <div class="sum-item">凑整金额：{{ statisticsInfo.roundAmount || 0 }}</div>
        <div class="sum-item">总价格2（已去杂，已凑整）：{{ statisticsInfo.finalTotalAmount || 0 }}</div>
      </div>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="hColumns"
        :data-source="hList"
        :pagination="false"
      >
      </a-table>
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <div slot="isTitle">
          <div class="table-item">重量</div>
          <div class="table-item">金额（含去杂）</div>
          <div class="table-item">其中去杂金额</div>
        </div>
        <div v-for="(item, index) in regenerantVosList" :key="index" :slot="item.name">
          <div class="table-item">{{ item.totalWeight }}</div>
          <div class="table-item">{{ item.totalAmount }}</div>
          <div class="table-item">{{ item.impurityAmount }}</div>
        </div>
      </a-table>
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="bColumns"
        :data-source="bList"
        :pagination="false"
      >
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { statistics, resourceSumExport } from '@/api/recyclable/resourceSum'
import CreateForm from './modules/CreateForm'
import debounce from 'lodash/debounce'
import { listAll } from '@/api/recyclable/postStationInfo'
import { listRecyclingKind, childrenList } from '@/api/recyclable/recyclingKind'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'BankInfo',
  components: {
    ATreeSelect: TreeSelect,
    CreateForm
  },
  data() {
    this.handleQuerySearch = debounce(this.handleQuerySearch, 500)
    return {
      list: [],
      stationListQuery: [],
      selectedRowKeys: [],
      selectedRows: [],
      daterangeCreateTime: [],
      regenerantVosList: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        stationIds: undefined,
        scaleIds: undefined,
        bankNo: null,
        bankName: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
      ],
      hColumns: [
        {
          title: '驿站数量',
          dataIndex: 'countStation',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '已绑定智能秤数量',
          dataIndex: 'countScale',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '重量单位',
          dataIndex: 'weight',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '金额单位',
          dataIndex: 'price',
          ellipsis: true,
          align: 'center'
        }
      ],
      hList: [],
      bColumns: [],
      bList: [],
      statusOptions: [
        { dictLabel: '正常', dictValue: '0' },
        { dictLabel: '删除', dictValue: '1' }
      ],
      replaceFields: { children: 'children', title: 'name', key: 'id', value: 'id' },
      kindList: [],
      scaleIds: [],
      statisticsInfo: {}
    }
  },
  filters: {},
  created() {
    this.setRangeTime()
    this.getList()
    this.listRecyclingKind()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询用户银行卡信息列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeCreateTime !== null &&
        this.daterangeCreateTime !== '' &&
        this.daterangeCreateTime.length !== 0
      ) {
        this.queryParam.params['beginTime'] = this.daterangeCreateTime[0]
        this.queryParam.params['endTime'] = this.daterangeCreateTime[1]
      }
      statistics({
        params: this.queryParam.params,
        scaleIds: this.queryParam.scaleIds,
        stationIds: this.queryParam.stationIds ? [this.queryParam.stationIds] : undefined
      }).then((response) => {
        // this.list = response.rows
        this.loading = false
        this.statisticsInfo = response.data
        this.formatStatisticsList(response)
      })
    },
    setRangeTime() {
      const d = new Date()
      const y = d.getFullYear()
      const m = d.getMonth() + 1
      const dd = d.getDate()
      const t = y + '-' + m + '-' + dd
      this.daterangeCreateTime = [t, t]
    },
    formatStatisticsList(response) {
      // 构建表格数据格式
      const arr = []
      let columns = []
      const childColumns = []
      const obj = {}
      this.bList = []
      this.list = []
      this.hList = []
      this.regenerantVosList = response.data.regenerantVosList
      for (const item of response.data.regenerantVosList) {
        if (item.totalWeight > 0) {
          childColumns.push({
            title: item.name,
            dataIndex: item.id,
            ellipsis: true,
            scopedSlots: { customRender: item.name },
            align: 'center'
          })
          // obj[item.id] = item.totalWeight + '\n' + item.totalAmount + '\n' + item.impurityAmount
        }
      }
      // obj.stationName = '总计' + response.data.countStation + '个'
      // obj.deviceId = '总计' + response.data.countScale + '个'
      if (response.data.stationAndScaleStaticRegenerantList.length === 0) {
        obj.recyclingKind = '0'
      }
      obj.type = '总计'
      this.list.push(obj)
      const kindColumn =
        childColumns.length > 0
          ? childColumns
          : [
              {
                title: '物品详情',
                dataIndex: 'recyclingKind',
                align: 'center'
              }
            ]
      // arr.push(obj)
      columns = columns.concat([
        {
          title: '驿站',
          dataIndex: 'stationName',
          width: '150px',
          align: 'center'
        },
        {
          title: '已绑定智能秤',
          dataIndex: 'deviceId',
          width: '150px',
          align: 'center'
        },
        ...kindColumn
        // ellipsis: true,
      ])
      // {
      //     title: '重量单位(KG)',
      //     children:
      //       childColumns.length > 0
      //         ? childColumns
      //         : [
      //             {
      //               title: '物品重量',
      //               dataIndex: 'recyclingKind',
      //               align: 'center'
      //             }
      //           ],
      //     // ellipsis: true,
      //     align: 'center'
      //   }
      this.hList.push({
        countStation: response.data.countStation,
        countScale: response.data.countScale,
        weight: 'KG',
        price: '元'
      })
      for (const item of response.data.stationAndScaleStaticRegenerantList) {
        const sObj = {}
        for (const rItem of item.regenerantVos) {
          sObj[rItem.id] = rItem.totalWeight
        }
        sObj.stationName = item.stationName
        sObj.deviceId = item.deviceId
        arr.push(sObj)
      }
      console.log('columns', columns)
      console.log('arr', arr)
      this.bColumns = columns
      const mcArr = kindColumn
      mcArr.unshift(
        {
          title: '类型',
          dataIndex: 'type',
          align: 'center',
          width: '300px'
        },
        kindColumn[0].title === '物品详情'
          ? {
              title: '',
              width: '0'
            }
          : {
              title: '',
              dataIndex: 'title',
              align: 'center',
              scopedSlots: { customRender: 'isTitle' },
              width: '150px'
            }
      )
      this.columns = mcArr
      this.bList = arr
      this.total = arr.length
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = []
      this.queryParam = {
        username: undefined,
        bankNo: undefined,
        bankName: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      // var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          // return delBankInfo(ids).then(() => {
          //   that.onSelectChange([], [])
          //   that.getList()
          //   that.$message.success('删除成功', 3)
          // })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          const params = {}
          params.params = that.queryParam.params
          params.params.beginTime = params.params.beginTime + ' 00:00:00'
          params.params.endTime = params.params.endTime + ' 23:59:59'
          params.scaleIds = that.queryParam.scaleIds || []
          params.stationIds = that.queryParam.stationIds ? [that.queryParam.stationIds] : []
          return resourceSumExport(params).then((response) => {
            console.log('resourceSumExport', response)
            // that.download(response.msg)
            // 获取文件名
            const fileName = response.headers['content-disposition'].split(';')[1].split('=')[1]
            that.download(fileName, 'excel', response.data)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    optionsFormat(options, value) {
      return this.selectDictLabel(options, value)
    },
    handleQuerySearch(value) {
      console.log('handleQuerySearch', value)
      this.qloading = true
      this.listPostStationInfo(value, 'stationListQuery', 'qloading')
    },
    handleQueryChange(value) {
      console.log('handleQueryChange', value)
      this.queryParam.stationIds = value
    },
    listPostStationInfo(name, arrName, loadingName) {
      listAll({
        stationName: name
      }).then((res) => {
        this[loadingName] = false
        this[arrName] = res.data
      })
    },
    /** 查询回收物种类列表 */
    listRecyclingKind() {
      listRecyclingKind(this.queryParam).then(async (response) => {
        const list = response.rows
        this.kindList = []
        let i = 0
        for (const item of list) {
          if (item.parentId === 0) {
            item.index = i
            // 获取子菜单
            const child = await this.childrenList(item)
            if (child && child.length > 0) {
              child[0].index = 0
              child[child.length - 1].isEnd = true
              item.children = child
            }
            this.kindList.push(item)
            i++
          }
        }
        console.log('kindList', this.kindList)
      })
    },
    childrenList(record) {
      return new Promise((resolve, reject) => {
        childrenList(record.id).then((res) => {
          resolve(res.data)
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.sum-wrap {
  display: flex;
  padding: 15px 0;
  .sum-item {
    margin-right: 30px;
    font-size: 14px;
    color: #000000d9;
  }
}
.table-item {
  position: relative;
  margin-left: -8px;
  margin-right: -8px;
  padding-bottom: 5px;
  padding-top: 5px;
  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #f2f2f2;
    }
  }
}
</style>
